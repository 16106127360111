// src/components/home/FeaturedProperties.js
import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import properties from '../../data/properties.json'; 

const FeaturedProperties = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: 'easeOut' } },
  };

  return (
    <motion.section
      className="py-16 bg-gray-100"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false }}
      variants={sectionVariants}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold text-center mb-12 text-gray-800"
          variants={itemVariants}
        >
          Bất Động Sản Nổi Bật
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {properties.map(({ id, image, name, price }) => (
            <motion.div
              key={id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
            >
              <img
                src={`/images/${image}`}
                alt={name}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-bold text-gray-800">{name}</h3>
                <p className="text-gray-600 mt-2">Giá thuê: ¥ {price}/tháng</p>
                <Link
                  to={`/properties/${id}`}
                  className="text-tokyo-red font-semibold hover:underline mt-4 block"
                >
                  Xem chi tiết
                </Link>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default FeaturedProperties;
