// src/pages/Home.js
import React from 'react';
import HeroSection from '../components/home/HeroSection';
import AboutSection from '../components/home/AboutSection';
import FeaturedProperties from '../components/home/FeaturedProperties';
import CustomerTestimonials from '../components/home/CustomerTestimonials';
import CallToAction from '../components/home/CallToAction';
import customerTestimonials from '../data/customerTestimonials.json'; // Import dữ liệu JSON

const Home = () => {
  return (
    <div className="bg-gray-50">
      <HeroSection />
      <AboutSection />
      <FeaturedProperties />
      <CustomerTestimonials testimonials={customerTestimonials} /> {/* Truyền dữ liệu vào component */}
      <CallToAction />
    </div>
  );
};

export default Home;
