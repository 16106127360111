import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home'; // Import Home từ pages
import About from './pages/About'; // Nếu bạn có tệp About.js
import Contact from './pages/Contact'; // Nếu bạn có tệp Contact.js

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} /> {/* Hiển thị Home từ pages/Home */}
          <Route path="/about" element={<About />} /> {/* Hiển thị About */}
          <Route path="/contact" element={<Contact />} /> {/* Hiển thị Contact */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
