import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const CustomerTestimonials = ({ testimonials }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const testimonialsPerPage = 4;
  const totalPages = Math.ceil(testimonials.length / testimonialsPerPage);
  const autoSlideInterval = 5000; 

  useEffect(() => {
    if (totalPages > 1) {
      const interval = setInterval(() => {
        setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
      }, autoSlideInterval);

      return () => clearInterval(interval); 
    }
  }, [totalPages]);

  const nextPage = () => setCurrentPage((prev) => (prev + 1) % totalPages);

  const prevPage = () => setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold text-center mb-12 text-gray-800"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
        >
          Khách Hàng Của Chúng Tôi
        </motion.h2>
        <div className="relative">
          <div className="overflow-hidden">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentPage}
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6"
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.5 }}
              >
                {testimonials
                  .slice(currentPage * testimonialsPerPage, (currentPage + 1) * testimonialsPerPage)
                  .map((testimonial) => (
                    <motion.div
                      key={testimonial.id}
                      className="bg-gray-100 rounded-lg overflow-hidden shadow-md transition-all duration-300 hover:shadow-xl"
                    >
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-6">
                        <h3 className="font-semibold text-gray-800 text-lg mb-2">{testimonial.name}</h3>
                        <p className="text-gray-600">{testimonial.comment}</p>
                      </div>
                    </motion.div>
                  ))}
              </motion.div>
            </AnimatePresence>
          </div>

          {totalPages > 1 && (
            <>
              <button
                onClick={prevPage}
                className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 hover:scale-110 rounded-full p-2 transition-all duration-300"
              >
                <ChevronLeft className="w-6 h-6 text-tokyo-red" />
              </button>
              <button
                onClick={nextPage}
                className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 hover:scale-110 rounded-full p-2 transition-all duration-300"
              >
                <ChevronRight className="w-6 h-6 text-tokyo-red" />
              </button>
            </>
          )}
        </div>

        {/* Dấu chấm chỉ số trang */}
        <div className="flex justify-center mt-8">
          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index)}
              className={`mx-1 w-3 h-3 rounded-full focus:outline-none transition-all duration-300 ${
                currentPage === index ? 'bg-tokyo-red' : 'bg-gray-300 hover:bg-gray-400'
              }`}
              aria-label={`Chuyển đến trang testimonials ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerTestimonials;

