import React from 'react';
import { motion } from 'framer-motion';
import { Building, Users, Star } from 'lucide-react';
import aboutSectionData from '../../data/aboutSectionData.json'; 

const AboutSection = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5, ease: 'easeOut' } },
  };

  return (
    <motion.section
      className="py-16 bg-white"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false }}
      variants={sectionVariants}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold text-center mb-12 text-gray-800"
          variants={itemVariants}
        >
          {aboutSectionData.title} 
        </motion.h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          {aboutSectionData.features.map(({ icon, title, description }, index) => {
            const Icon = { Building, Users, Star }[icon]; 
            return (
              <motion.div
                key={index}
                className="p-4"
                variants={itemVariants}
                whileHover={{ scale: 1.1 }}
              >
                <Icon className="w-12 h-12 text-tokyo-red mx-auto mb-4" />
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
                <p className="text-gray-600">{description}</p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </motion.section>
  );
};

export default AboutSection;
