import { FaPhone, FaEnvelope, FaLinkedin, FaFacebook, FaAward, FaHome, FaChartLine, FaHandshake } from 'react-icons/fa';

export default function RealEstateConsultantProfileFullPage() {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="flex flex-col lg:flex-row gap-8 p-8">
        <aside className="lg:w-1/3">
          <div className="bg-white rounded-lg shadow-md overflow-hidden sticky top-8 self-start">
            <img
              className="w-full h-auto object-cover object-center"
              src="/images/avata.jpg"
              alt="Nguyễn Thị B"
            />
            <div className="p-6">
              <h1 className="text-3xl font-bold text-gray-900 mb-2">NGUYEN XUAN HUYNH</h1>
              <p className="text-xl text-blue-600 font-semibold mb-4">Chuyên viên Tư vấn Bất động sản Cao cấp</p>
              <div className="space-y-4">
                <button
                  onClick={() => window.location.href = 'tel:+84901234567'}
                  className="flex items-center text-gray-600 hover:text-blue-500 transition duration-300"
                >
                  <FaPhone className="w-5 h-5 mr-2" />
                  <span>080-1158-5688</span>
                </button>
                <button
                  onClick={() => window.location.href = 'mailto:nguyenthib@example.com'}
                  className="flex items-center text-gray-600 hover:text-blue-500 transition duration-300"
                >
                  <FaEnvelope className="w-5 h-5 mr-2" />
                  <span>xuanhuynh@example.com</span>
                </button>
              </div>
              <div className="flex justify-start space-x-4 mt-6">
                <button onClick={() => window.open("https://linkedin.com", "_blank")} className="text-gray-400 hover:text-blue-500 transition duration-300">
                  <FaLinkedin className="w-6 h-6" />
                </button>
                <button onClick={() => window.open("https://facebook.com", "_blank")} className="text-gray-400 hover:text-blue-500 transition duration-300">
                  <FaFacebook className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </aside>

        <main className="lg:w-2/3 space-y-8 flex-grow">
          <section className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Giới thiệu</h2>
            <p className="text-gray-600 leading-relaxed">
              Với hơn 10 năm kinh nghiệm trong lĩnh vực bất động sản, tôi tự hào là đối tác đáng tin cậy cho khách hàng trong việc tìm kiếm, 
              mua bán và đầu tư bất động sản. Tôi cam kết mang đến dịch vụ chuyên nghiệp, tận tâm và hiệu quả nhất cho mọi khách hàng.
            </p>
          </section>

          <section className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Chuyên môn</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {[
                { icon: FaHome, text: 'Tư vấn mua bán nhà ở' },
                { icon: FaChartLine, text: 'Phân tích thị trường' },
                { icon: FaHandshake, text: 'Đàm phán hợp đồng' },
                { icon: FaAward, text: 'Định giá bất động sản' },
              ].map((skill, index) => (
                <div key={index} className="flex items-center space-x-2 text-gray-700 bg-gray-50 p-3 rounded-lg transition duration-300 hover:bg-blue-50 hover:text-blue-600">
                  <skill.icon className="w-6 h-6 text-blue-500" />
                  <span>{skill.text}</span>
                </div>
              ))}
            </div>
          </section>

          <section className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Thành tích</h2>
            <ul className="space-y-2 text-gray-600">
              {[
                'Top 5 Chuyên viên tư vấn xuất sắc năm 2022',
                'Hoàn thành hơn 100 giao dịch thành công trong năm 2021',
                'Chứng chỉ Môi giới Bất động sản chuyên nghiệp',
                'Thành viên Hiệp hội Môi giới Bất động sản Việt Nam',
              ].map((achievement, index) => (
                <li key={index} className="flex items-start">
                  <FaAward className="w-5 h-5 text-yellow-500 mr-2 mt-1 flex-shrink-0" />
                  <span>{achievement}</span>
                </li>
              ))}
            </ul>
          </section>

          <section className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Dự án tiêu biểu</h2>
            <div className="grid gap-6 md:grid-cols-2">
              {[
                { name: 'Khu đô thị Xanh', desc: 'Tư vấn và hoàn thành 20 giao dịch trong dự án cao cấp' },
                { name: 'Tòa nhà Thương mại Central', desc: 'Đàm phán thành công hợp đồng cho thuê toàn bộ tầng thương mại' },
                { name: 'Biệt thự Ven Sông', desc: 'Tư vấn đầu tư và quản lý danh mục 5 biệt thự cao cấp' },
                { name: 'Khu phức hợp Sunrise', desc: 'Hỗ trợ khách hàng mua 10 căn hộ đầu tư sinh lời' },
              ].map((project, index) => (
                <div key={index} className="border-l-4 border-blue-500 pl-4 transition duration-300 hover:bg-blue-50">
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">{project.name}</h3>
                  <p className="text-gray-600">{project.desc}</p>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}
