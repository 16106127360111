import React from 'react';
import { motion } from 'framer-motion';
import heroSectionData from '../../data/heroSectionData.json'; // Import dữ liệu từ JSON

const HeroSection = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  return (
    <section
      className="relative bg-cover bg-center py-32 lg:py-48"
      style={{ backgroundImage: `url(${heroSectionData.backgroundImage})` }} 
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="container mx-auto px-4 text-center relative z-10">
        {/* Title */}
        <motion.h1
          className="text-4xl md:text-6xl font-extrabold text-white mb-6"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          variants={sectionVariants}
        >
          {heroSectionData.title} 
        </motion.h1>

        {/* Subtitle */}
        <motion.p
          className="text-lg md:text-xl text-white opacity-90 mb-8 max-w-2xl mx-auto"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          variants={sectionVariants}
          transition={{ delay: 0.2 }}
        >
          {heroSectionData.subtitle} 
        </motion.p>
      </div>

      {/* Social Section */}
      <motion.div
        className="absolute bottom-8 left-0 right-0 text-center"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { delay: 0.4, duration: 0.8 } }}
      >
        <p className="text-white text-lg font-semibold mb-4">
          Kết nối với Huynh qua mạng xã hội!
        </p>
        <div className="flex justify-center gap-6">
          {heroSectionData.socialLinks.map(({ image, url, label }, index) => (
            <a
              key={index}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-16 h-16 flex items-center justify-center rounded-full bg-white bg-opacity-20 hover:bg-opacity-40 text-white transition duration-300 transform hover:scale-110"
              aria-label={label}
            >
              <img
                src={image} 
                alt={label} 
                className="w-10 h-10"  
              />
            </a>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default HeroSection;
