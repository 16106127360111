import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto grid md:grid-cols-3 gap-8">
          <div>
            <h4 className="text-xl font-bold mb-4">株式会社 Utility House </h4>
            <p className="text-gray-300">東京都豊島区南大塚2-37-12和田ビル3階</p>
            <p className="text-gray-400">Chuyên cung cấp bất động sản cao cấp tại Tokyo</p>
          </div>
          <div>
            <h4 className="text-xl font-bold mb-4">Liên Hệ</h4>
            <p className="text-gray-300">Email: huynh010199@gmail.com</p>
            <p className="text-gray-300">Điện thoại: 080-1158-5688</p>
          </div>
          <div>
            <h4 className="text-xl font-bold mb-4">Giấy phép kinh doanh</h4>
            <p className="text-gray-300">国土交通大臣（１）第10128号 </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
