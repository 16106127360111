import React, { useState } from 'react';

function Contact() {
  // States để lưu trữ thông tin form
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">Contact Us</h1>
      <div className="bg-white shadow-lg rounded-lg p-8">
        <p className="text-gray-600 text-center mb-6">Để lại thông tin liên hệ của bạn. Tôi sẽ liên hệ lại sớm nhất có thể !</p>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block mb-2 text-lg font-semibold text-gray-700">Họ và tên:</label>
            <input 
              type="text" 
              id="name" 
              name="name"
              value={formData.name} 
              onChange={handleChange} 
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none" 
            />
          </div>

          <div>
            <label htmlFor="email" className="block mb-2 text-lg font-semibold text-gray-700">Email:</label>
            <input 
              type="email" 
              id="email" 
              name="email"
              value={formData.email} 
              onChange={handleChange} 
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none" 
            />
          </div>

          <div>
            <label htmlFor="message" className="block mb-2 text-lg font-semibold text-gray-700">Message:</label>
            <textarea 
              id="message" 
              name="message"
              value={formData.message} 
              onChange={handleChange} 
              rows="6" 
              className="w-full px-4 py-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>

          <div className="text-center">
            <button type="submit" className="w-full py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500">Send Message</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
