import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Home, Info, Phone, Menu, X } from 'lucide-react';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-primary-100 text-primary-800 shadow-md">
      <div className="container mx-auto px-4 py-4">
        <nav className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold text-tokyo-red flex items-center space-x-2">
            <img src="/logo192.png" alt="Logo" className="w-8 h-8" />
            <span>Huynh-UtilityHouse</span>
          </Link>

          {/* Desktop Navigation */}
          <ul className="hidden md:flex space-x-8 items-center">
            <li>
              <Link
                to="/"
                className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
              >
                <Home className="w-5 h-5" />
                <span>Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
              >
                <Info className="w-5 h-5" />
                <span>Giới thiệu</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
              >
                <Phone className="w-5 h-5" />
                <span>Liên hệ</span>
              </Link>
            </li>
          </ul>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-tokyo-red focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <X className="w-8 h-8" /> : <Menu className="w-8 h-8" />}
          </button>
        </nav>

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className="mt-4 bg-white shadow-md rounded-md md:hidden">
            <ul className="space-y-4 py-4 px-6">
              <li>
                <Link
                  to="/"
                  className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Home className="w-5 h-5" />
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Info className="w-5 h-5" />
                  <span>Giới thiệu</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="flex items-center space-x-2 text-lg hover:text-tokyo-blue transition duration-300"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <Phone className="w-5 h-5" />
                  <span>Liên hệ</span>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
