import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const CallToAction = () => {
  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, ease: 'easeOut' } },
  };

  return (
    <motion.section
      className="bg-tokyo-red text-white py-16 text-center"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false }}
      variants={sectionVariants}
    >
      <div className="container mx-auto px-4">
        <motion.h2
          className="text-3xl font-bold mb-6"
          variants={itemVariants}
        >
          Sẵn sàng tìm căn nhà hợp lý cho bạn!
        </motion.h2>
        <Link
          to="/contact"
          className="bg-white text-tokyo-red px-8 py-3 rounded-full font-semibold hover:bg-gray-100 
          transition transform hover:-translate-y-1 hover:shadow-xl"
        >
          Liên hệ với Huynh ngay
        </Link>
      </div>
    </motion.section>
  );
};

export default CallToAction;
